import React from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

export const sentryInit = () => {
  Sentry.init({
    dsn: 'https://e719966da6adba3fcaf3c8274664fa77@o4506956509085696.ingest.us.sentry.io/4506959799386112',
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
      // Sentry.feedbackIntegration({
      //   colorScheme: 'system',
      //   // isEmailRequired: true,
      // }),
    ],
    environment: process.env.REACT_APP_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      // https://development.my.checkbird.app
      /^https:\/\/development\.my\.checkbird\.app\/api/,
      // https://staging.my.checkbird.app
      /^https:\/\/staging\.my\.checkbird\.app\/api/,
      // https://my.checkbird.app
      /^https:\/\/my\.checkbird\.app\/api/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};
