import { format, getTime, formatDistanceToNow } from 'date-fns';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import customParseFormat from 'dayjs/plugin/customParseFormat.js';

// ----------------------------------------------------------------------

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function fGoogleCalendarDate(date: InputValue) {
  // Format the date to 'YYYYMMDDTHHmmss[Z]' (Z for UTC time)
  return `${dayjs(date).utc().format('YYYYMMDDTHHmmss')}Z`;
}

/*
  Convert time from selected timezone to UTC
*/
export function fToUTC(date: InputValue, tz: string) {
  const result = date ? dayjs.tz(date, 'HH:mm', tz).utc().format('HH:mm') : '';
  return result;
}

/*
  Convert time from UTC to selected timezone
*/
export function fToSelectedTimezone(date: InputValue, tz: string) {
  const result = date ? dayjs.utc(date, 'HH:mm').tz(tz).format('HH:mm') : '';
  return result;
}
