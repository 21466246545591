import { CustomFile } from 'src/components/upload';

// ----------------------------------------------------------------------

export type ICustomerTableFilterValue = string | string[];

export type ICustomerTableFilters = {
  name: string;
  status: string;
};

export type ICustomerTableSmallFilters = {
  name: string;
  status: string;
};

// ----------------------------------------------------------------------

export type ICustomerSocialLink = {
  facebook: string;
  instagram: string;
  linkedin: string;
  twitter: string;
};

export type ICustomerProfileCover = {
  name: string;
  role: string;
  coverUrl: string;
  photoUrl: string;
};

export type ICustomerProfile = {
  id: string;
  role: string;
  quote: string;
  email: string;
  school: string;
  country: string;
  company: string;
  totalFollowers: number;
  totalFollowing: number;
  socialLinks: ICustomerSocialLink;
};

export type ICustomerProfileFollower = {
  id: string;
  name: string;
  country: string;
  photoUrl: string;
};

export type ICustomerProfileGallery = {
  id: string;
  title: string;
  imageUrl: string;
  postedAt: Date;
};

export type ICustomerProfileFriend = {
  id: string;
  name: string;
  role: string;
  photoUrl: string;
};

export type ICustomerProfilePost = {
  id: string;
  media: string;
  message: string;
  createdAt: Date;
  personLikes: {
    name: string;
    photoUrl: string;
  }[];
  comments: {
    id: string;
    message: string;
    createdAt: Date;
    author: {
      id: string;
      name: string;
      photoUrl: string;
    };
  }[];
};

export type ICustomerCard = {
  id: string;
  name: string;
  role: string;
  coverUrl: string;
  photoUrl: string;
  totalPosts: number;
  totalFollowers: number;
  totalFollowing: number;
};

export type ICustomerCardSmall = {
  id: string;
  name: string;
  email: string;
  phoneCode: string;
  phoneNumber: string;
  photoUrl: string;
};

export type ICustomerItem = {
  id: string;
  person: {
    email: string;
    name: string;
    phoneCode: string;
    phoneNumber: string;
    address: string;
    birthDate: Date;
    photoUrl: string;
  };
  status: CustomerStatus;
  userId: number;
  companyId: number;
  createdAt: Date;
};

export type ICustomerItemLight = {
  id?: string;
  person: {
    email?: string | null;
    name?: string | null;
    phoneCode: string;
    phoneNumber: string;
    address?: string | null;
    birthDate?: Date | null;
    photoUrl?: string | null;
  };
  status?: CustomerStatus;
  userId?: number;
  companyId?: number | null;
};

export type ICustomerAccount = {
  email: string;
  isPublic: boolean;
  name: string;
  city: string | null;
  state: string | null;
  about: string | null;
  country: string | null;
  address: string | null;
  zipCode: string | null;
  phoneCode: string | null;
  phoneNumber: string | null;
  photoUrl: CustomFile | string | null;
};

export type ICustomerAccountBillingHistory = {
  id: string;
  price: number;
  createdAt: Date;
  invoiceNumber: string;
};

export type ICustomerAccountChangePassword = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export type GetOneParams = {
	companyId: number;
	customerId: number;
}

export type CreateBody = {
  person: {
    email?: string | null;
    name?: string | null;
    phoneCode: string;
    phoneNumber: string;
    address?: string | null;
    birthDate?: Date | null;
    photoUrl?: string | null;
  };
  status?: CustomerStatus;
  userId?: number;
};

export type UpdateOneBody = {
  person: {
    // id?: number;
    name?: string | null;
    email?: string | null;
    phoneCode?: string | null;
    phoneNumber?: string | null;
    address?: string | null;
    birthDate?: Date | null;
    photoUrl?: string | null;
  };
  status?: CustomerStatus;
  userId?: number;
};

export enum CustomerStatus {
  ACTIVE = 'ACTIVE',
  BANNED = 'BANNED',
}

export type RemoveParams = {
  companyId: number;
  customerId: string;
};
