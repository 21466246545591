import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
// utils
import { endpoints, useAuthHeader, fetcher, create, update } from 'src/utils/axios';
import { AxiosRequestConfig } from 'axios';
import { CreateBody, IUserItemLight, LinkToEmployeeParams, UpdateOneBody } from 'src/types/user';
import { isEmpty, isStatusSuccessful } from 'src/utils/general';

// ----------------------------------------------------------------------

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

let swrKey: string | [string, AxiosRequestConfig, boolean];
export const userSwrKey = () => swrKey;

export function useGetUserByAuth0Id({ auth0Id }: { auth0Id?: string }) {
  const endpointsFN = endpoints({ id: auth0Id });
  const url = endpointsFN.user.getOneByAuth0Id;

  const token = useAuthHeader();
  const isReady: boolean = !!auth0Id && !!token;
  swrKey = [url, {}, isReady];
  const { data: swrData, isLoading, error, isValidating } = useSWR(swrKey, fetcher, options);

  const {
    axiosData: { message, data },
    status,
  } = swrData || { axiosData: {} };

  const memoizedValue = useMemo(
    () => ({
      user: data || {},
      userLoading: isLoading,
      userError: error,
      userValidating: isValidating,
      userEmpty: !isLoading && isEmpty(data),
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export async function createUser(userData?: CreateBody) {
  /**
   * Work on server
   */
  const body = { person: userData?.person };
  const config = {
    data: body,
  };

  const allEndpoints = endpoints({});
  const url = allEndpoints.user.create;

  const response = await create([url, config]);

  // Do not update local state if nothing changed in the db.
  if (!isStatusSuccessful(response.status)) return response;

  /**
   * Work in local
   */
  userData = { ...response.axiosData.data };
  mutate(
    swrKey,
    (currentData: any) => {
      const { axiosData } = currentData;
      const data = userData;

      const result = {
        ...currentData,
        axiosData: {
          ...axiosData,
          data,
        },
      };

      return result;
    },
    false
  );

  return response;
}

export async function updateUser(userData: IUserItemLight) {
  /**
   * Work on server
   */
  const { id, person, role } = userData;
  const allEndpoints = endpoints({ id });
  const updateUrl = allEndpoints.user.updateOne;

  const body: UpdateOneBody = { person, role };
  if (person?.birthDate) body.person.birthDate = person.birthDate;

  const config = {
    data: body,
  };

  const response = await update([updateUrl, config]);

  // Do not update local state if nothing changed in the db.
  if (!isStatusSuccessful(response.status)) return response;

  /**
   * Work in local
   */
  mutate(
    swrKey,
    (currentData: any) => {
      const { axiosData } = currentData;
      const data = {
        ...axiosData.data,
        ...userData,
        person: {
          ...axiosData.data.person,
          ...userData.person,
        },
      };

      const result = {
        ...currentData,
        axiosData: {
          ...axiosData,
          data,
        },
      };

      return result;
    },
    false
  );

  return response;
}

export async function linkToEmployee(data: LinkToEmployeeParams) {
  /**
   * Work on server
   */
  const { userId, employeeId } = data;
  const allEndpoints = endpoints({ userId, employeeId });
  const updateUrl = allEndpoints.user.linkToEmployee;

  const response = await update([updateUrl, {}]);

  return response;
}

export function useGetAllUsers() {
  const endpointsFN = endpoints({});
  const url = endpointsFN.user.getAll;

  const token = useAuthHeader();
  const isReady: boolean = !!token;
  const swrKeyGetAll = [url, {}, isReady];
  const { data: swrData, isLoading, error, isValidating } = useSWR(swrKeyGetAll, fetcher, options);

  const {
    axiosData: { message, data },
    status,
  } = swrData || { axiosData: {} };

  const memoizedValue = useMemo(
    () => ({
      users: data || {},
      usersLoading: isLoading,
      usersError: error,
      usersValidating: isValidating,
      usersEmpty: !isLoading && isEmpty(data),
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
