//
import { ServiceStatus } from 'src/types/service';
import { _mock } from './_mock';

// ----------------------------------------------------------------------

export const SERVICE_STATUS_OPTIONS = [
  { value: ServiceStatus.ACTIVE, label: 'Active' },
  { value: ServiceStatus.INACTIVE, label: 'Inactive' },
];
