import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// EVENT
const EventNewPage = lazy(() => import('src/pages/dashboard/event/new'));
const EventCancelPage = lazy(() => import('src/pages/dashboard/event/cancel'));

// ----------------------------------------------------------------------

export const eventRoutes = [
  {
    path: 'event',
    element: (
      // TODO: Clean up
      // <AuthGuard>
        // <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        // </DashboardLayout>
      // </AuthGuard>
    ),
    children: [
      {
        path: 'company',
        children: [
          { element: <EventNewPage />, index: true },
          { path: ':id/new', element: <EventNewPage /> },
          { path: ':companyId/cancel/:appointmentPublicId', element: <EventCancelPage /> },
        ],
      },
    ],
  },
];
