import ReactGA from 'react-ga4';

const measurementId = 'G-7Y1TSS4NW5';
const isProduction = process.env.NODE_ENV === 'production';

export const gAnalyticsInit = async () => {
  if (isProduction) {
    await ReactGA.initialize(measurementId);
  }
};
