import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/calendar'));

// APP
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));

// COMPANY
const CompanyCreatePage = lazy(() => import('src/pages/dashboard/company/new'));
const CompanyAccountPage = lazy(() => import('src/pages/dashboard/company/account'));

// Customer
const CustomerListPage = lazy(() => import('src/pages/dashboard/customer/list')); // src/pages/dashboard/customer/
const CustomerCreatePage = lazy(() => import('src/pages/dashboard/customer/new'));
const CustomerEditPage = lazy(() => import('src/pages/dashboard/customer/edit'));

// Employee
const EmployeeListPage = lazy(() => import('src/pages/dashboard/employee/list'));
const EmployeeCreatePage = lazy(() => import('src/pages/dashboard/employee/new'));
const EmployeeEditPage = lazy(() => import('src/pages/dashboard/employee/edit'));

// Service
const ServiceListPage = lazy(() => import('src/pages/dashboard/service/list'));
const ServiceCreatePage = lazy(() => import('src/pages/dashboard/service/new'));
const ServiceEditPage = lazy(() => import('src/pages/dashboard/service/edit'));

// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));

// EVENT
const EventNewPage = lazy(() => import('src/pages/dashboard/event/new'));
const EventCancelPage = lazy(() => import('src/pages/dashboard/event/cancel'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'calendar', element: <CalendarPage /> },
      {
        path: 'customer',
        children: [
          { element: <CustomerListPage />, index: true },
          { path: 'list', element: <CustomerListPage /> },
          { path: 'new', element: <CustomerCreatePage /> },
          { path: ':id/edit', element: <CustomerEditPage /> },
        ],
      },
      {
        path: 'employee',
        children: [
          { element: <EmployeeListPage />, index: true },
          { path: 'list', element: <EmployeeListPage /> },
          { path: 'new', element: <EmployeeCreatePage /> },
          { path: ':id/edit', element: <EmployeeEditPage /> },
        ],
      },
      {
        path: 'service',
        children: [
          { element: <ServiceListPage />, index: true },
          { path: 'list', element: <ServiceListPage /> },
          { path: 'new', element: <ServiceCreatePage /> },
          { path: ':id/edit', element: <ServiceEditPage /> },
        ],
      },
      {
        path: 'user',
        children: [
          // { element: <UserProfilePage />, index: true },
          // { path: 'profile', element: <UserProfilePage /> },
          // { path: 'cards', element: <UserCardsPage /> },
          // { path: 'list', element: <UserListPage /> },
          // { path: 'new', element: <UserCreatePage /> },
          // { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'company',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'new', element: <CompanyCreatePage /> },
          { path: 'account', element: <CompanyAccountPage /> },
        ],
      },
      {
        path: 'event',
        children: [
          { element: <EventNewPage />, index: true },
          { path: 'new', element: <EventNewPage /> },
          { path: 'cancel', element: <EventCancelPage /> },
        ],
      },
    ],
  },
];
