import { CustomFile } from 'src/components/upload';

// ----------------------------------------------------------------------

export type IEmployeeTableFilterValue = string | string[];

export type IEmployeeTableFilters = {
  name: string;
  role: string[];
  status: string;
};

export type IEmployeeTableSmallFilters = {
  name: string;
  status: string;
};

// ----------------------------------------------------------------------

export type IEmployeeSocialLink = {
  facebook: string;
  instagram: string;
  linkedin: string;
  twitter: string;
};

export type IEmployeeProfileCover = {
  name: string;
  role: string;
  coverUrl: string;
  photoUrl: string;
};

export type IEmployeeProfile = {
  id: string;
  role: string;
  quote: string;
  email: string;
  school: string;
  country: string;
  company: string;
  totalFollowers: number;
  totalFollowing: number;
  socialLinks: IEmployeeSocialLink;
};

export type IEmployeeProfileFollower = {
  id: string;
  name: string;
  country: string;
  photoUrl: string;
};

export type IEmployeeProfileGallery = {
  id: string;
  title: string;
  imageUrl: string;
  postedAt: Date;
};

export type IEmployeeProfileFriend = {
  id: string;
  name: string;
  role: string;
  photoUrl: string;
};

export type IEmployeeProfilePost = {
  id: string;
  media: string;
  message: string;
  createdAt: Date;
  personLikes: {
    name: string;
    photoUrl: string;
  }[];
  comments: {
    id: string;
    message: string;
    createdAt: Date;
    author: {
      id: string;
      name: string;
      photoUrl: string;
    };
  }[];
};

export type IEmployeeCard = {
  id: string;
  name: string;
  role: string;
  coverUrl: string;
  photoUrl: string;
  totalPosts: number;
  totalFollowers: number;
  totalFollowing: number;
};

export type IEmployeeCardSmall = {
  id: string;
  name: string;
  email: string;
  phoneCode: string;
  phoneNumber: string;
  photoUrl: string;
};

export type IEmployeeItem = {
  id: string;
  person: {
    email: string;
    name: string;
    phoneCode: string;
    phoneNumber: string;
    address: string;
    birthDate: Date;
    photoUrl: string;
  };
  start: Date;
  end: Date;
  daysOffAnnual: number;
  daysOffLeft: number;
  daysOffTaken: number;
  salaryAnnual: number;
  role: EmployeeRoles;
  status: EmployeeStatus;
  userId: number;
  companyId: number;
  createdAt: Date;
};

export type IEmployeeItemLight = {
  id?: string;
  person: {
    email: string;
    name?: string | null;
    phoneCode?: string | null;
    phoneNumber?: string | null;
    address?: string | null;
    birthDate?: Date | null;
    photoUrl?: string | null;
  };
  start?: Date | null;
  end?: Date | null;
  daysOffAnnual?: number | null;
  daysOffLeft?: number | null;
  daysOffTaken?: number | null;
  salaryAnnual?: number | null;
  role?: EmployeeRoles;
  status?: EmployeeStatus;
  userId?: number;
  companyId?: number | null;
};

export type IEmployeeAccount = {
  email: string;
  isPublic: boolean;
  name: string;
  city: string | null;
  state: string | null;
  about: string | null;
  country: string | null;
  address: string | null;
  zipCode: string | null;
  phoneCode: string | null;
  phoneNumber: string | null;
  photoUrl: CustomFile | string | null;
};

export type IEmployeeAccountBillingHistory = {
  id: string;
  price: number;
  createdAt: Date;
  invoiceNumber: string;
};

export type IEmployeeAccountChangePassword = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export type CreateBody = {
  person: {
    email: string;
    name?: string | null;
    phoneCode?: string | null;
    phoneNumber?: string | null;
    address?: string | null;
    birthDate?: Date | null;
    photoUrl?: string | null;
  };
  start?: Date | null;
  end?: Date | null;
  daysOffAnnual?: number | null;
  daysOffLeft?: number | null;
  daysOffTaken?: number | null;
  salaryAnnual?: number | null;
  role?: EmployeeRoles;
  status?: EmployeeStatus;
  userId?: number;
};

export type UpdateOneBody = {
  person: {
    // id?: number;
    name?: string | null;
    email?: string | null;
    phoneCode?: string | null;
    phoneNumber?: string | null;
    address?: string | null;
    birthDate?: Date | null;
    photoUrl?: string | null;
  };
  start?: Date | null;
  end?: Date | null;
  daysOffAnnual?: number | null;
  daysOffLeft?: number | null;
  daysOffTaken?: number | null;
  salaryAnnual?: number | null;
  role?: EmployeeRoles;
  status?: EmployeeStatus;
  userId?: number;
};

export enum EmployeeRoles {
  OWNER = 'OWNER',
  MANAGER = 'MANAGER',
  STAFF = 'STAFF',
}

export enum EmployeeStatus {
  ACTIVE = 'ACTIVE',
  ON_VACATION = 'ON_VACATION',
  INACTIVE = 'INACTIVE',
}

export type GetAllParams = {
  companyId?: number;
};
export type GetAllQuery = {
  serviceId?: number;
};

export interface GetAllReq extends GetAllParams, GetAllQuery {}

export type RemoveParams = {
  companyId: number;
  employeeId: string;
};
