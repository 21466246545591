import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  redirectTo?: string;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, redirectTo = '/', ...other }, ref) => {
    // OR using local (public folder)
    // -------------------------------------------------------
    const logo = (
      <Box
        component="img"
        src="/logo/logo_checkbird_single.png" // => your path
        sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href={redirectTo} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export const LogoFull = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, redirectTo = '/', ...other }, ref) => {
    const logo = (
      <Box
        component="img"
        src="/logo/logo_checkbird_full.svg" // => your path
        sx={{ width: 150, height: 150, cursor: 'pointer', ...sx }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href={redirectTo} sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
