//
import { EmployeeStatus } from 'src/types/employee';
import { _mock } from './_mock';

// ----------------------------------------------------------------------

export const EMPLOYEE_STATUS_OPTIONS = [
  { value: EmployeeStatus.ACTIVE, label: 'Active' },
  { value: EmployeeStatus.ON_VACATION, label: 'On Vacation' },
  { value: EmployeeStatus.INACTIVE, label: 'Inactive' },
];
