import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/event/new'));

// USERS
const UsersListPage = lazy(() => import('src/pages/panel/users/list'));

// ----------------------------------------------------------------------

export const panelRoutes = [
  {
    path: 'panel',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      // { path: 'calendar', element: <CalendarPage /> },
      {
        path: 'users',
        children: [
          { element: <UsersListPage />, index: true },
          { path: 'list', element: <UsersListPage /> },
        ],
      },
    ],
  },
];
