export type Company = {
  id: number;
  email: string | null;
  name: string | null;
  phoneCode: string | null;
  phoneNumber: string | null;
  fullDescription: string | null;
  shortDescription: string | null;
  photoUrl: string | null;
  coverPhotoUrl: string | null;
  isPublic: boolean;
  plan: Plans;
  planExpiresAt: Date | null;
  location: Location | null;
  locationId: number | null;
  createdAt: Date;
  updatedAt: Date;
};

export type ICompanyItem = {
  id?: number;
  email: string;
  name: string;
  phoneCode: string;
  phoneNumber: string;
  fullDescription?: string | null;
  shortDescription?: string | null;
  photoUrl?: string | null;
  coverPhotoUrl?: string | null;
  isPublic: boolean;
  locationId: number;
  userId: number;
};

export type GetOneParams = {
  id: number;
};

export type CreateBody = {
  email: string;
  name: string;
  phoneCode: string;
  phoneNumber: string;
  fullDescription?: string | null;
  shortDescription?: string | null;
  photoUrl?: string | null;
  coverPhotoUrl?: string | null;
  isPublic: boolean;
  locationId: number;
  userId: number;
};

export type UpdateOneParams = {
  id: number;
};

export type UpdateOneBody = {
  id?: number;
  email?: string;
  name?: string;
  phoneCode?: string;
  phoneNumber?: string;
  fullDescription?: string | null;
  shortDescription?: string | null;
  photoUrl?: string | null;
  coverPhotoUrl?: string | null;
  isPublic?: boolean;
  locationId?: number;
  userId?: number;
};

export enum Plans {
  FREE = 'FREE',
  TRIAL = 'TRIAL',
  PRO = 'PRO',
  PREMIUM = 'PREMIUM',
}

export enum CompanyTabs {
  GENERAL = 'general',
  WORK_HOURS = 'workHours',
  PROFILE = 'profile',
  NOTIFICATIONS = 'notifications',
}
