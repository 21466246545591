/**
 * Checks if the provided value is "empty".
 *
 * An "empty" value is defined as one of the following:
 * - null or undefined.
 * - An array or string with a length of 0.
 * - An object with no own enumerable properties.
 *
 */
const isEmpty = (value: object | string | Array<unknown> | null | undefined): boolean => {
  // Check for null or undefined
  if (value == null) {
    return true;
  }

  // Check for arrays or strings
  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length === 0;
  }

  // Check for objects
  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  // Non-object, non-string, non-array, non-null/undefined values are not empty
  return false;
};

const isStatusSuccessful = (status: number) => status >= 200 && status <= 299;

export { isEmpty, isStatusSuccessful };
