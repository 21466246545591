import { Employee, Person } from 'src/auth/types';
import { CustomFile } from 'src/components/upload';

// ----------------------------------------------------------------------

export type IServiceTableFilterValue = string | string[];

export type IServiceTableFilters = {
  name: string;
  status: string;
};

export type IServiceTableSmallFilters = {
  name: string;
  status: string;
};

// ----------------------------------------------------------------------

export type IServiceSocialLink = {
  facebook: string;
  instagram: string;
  linkedin: string;
  twitter: string;
};

export type IServiceProfileCover = {
  name: string;
  role: string;
  coverUrl: string;
  photoUrl: string;
};

export type IServiceProfile = {
  id: string;
  role: string;
  quote: string;
  email: string;
  school: string;
  country: string;
  company: string;
  totalFollowers: number;
  totalFollowing: number;
  socialLinks: IServiceSocialLink;
};

export type IServiceProfileFollower = {
  id: string;
  name: string;
  country: string;
  photoUrl: string;
};

export type IServiceProfileGallery = {
  id: string;
  title: string;
  imageUrl: string;
  postedAt: Date;
};

export type IServiceProfileFriend = {
  id: string;
  name: string;
  role: string;
  photoUrl: string;
};

export type IServiceProfilePost = {
  id: string;
  media: string;
  message: string;
  createdAt: Date;
  personLikes: {
    name: string;
    photoUrl: string;
  }[];
  comments: {
    id: string;
    message: string;
    createdAt: Date;
    author: {
      id: string;
      name: string;
      photoUrl: string;
    };
  }[];
};

export type IServiceCard = {
  id: string;
  name: string;
  role: string;
  coverUrl: string;
  photoUrl: string;
  totalPosts: number;
  totalFollowers: number;
  totalFollowing: number;
};

export type IServiceCardSmall = {
  id: string;
  name: string;
  email: string;
  phoneCode: string;
  phoneNumber: string;
  photoUrl: string;
};

export type IServiceItem = {
  id: string;
  name: string;
  category: string;
  shortDescription: string;
  fullDescription: string;
  price: number;
  duration: number;
  preparation: number;
  status: ServiceStatus;
  photoUrl: string;
  companyId: number;
  employees: Employee[];
  createdAt: Date;
  updatedAt: Date;
};

export type IServiceItemLight = {
  id?: string;
  name?: string | null;
  category?: string | null;
  shortDescription?: string | null;
  fullDescription?: string | null;
  price?: number | null;
  duration?: number | null;
  preparation?: number | null;
  status?: ServiceStatus;
  photoUrl?: string | null;
  companyId?: number | null;
  employeeIds?: number[] | null;
};

export type IServiceAccount = {
  email: string;
  isPublic: boolean;
  name: string;
  city: string | null;
  state: string | null;
  about: string | null;
  country: string | null;
  address: string | null;
  zipCode: string | null;
  phoneCode: string | null;
  phoneNumber: string | null;
  photoUrl: CustomFile | string | null;
};

export type IServiceAccountBillingHistory = {
  id: string;
  price: number;
  createdAt: Date;
  invoiceNumber: string;
};

export type IServiceAccountChangePassword = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export type CategoriesAndServices = {
  category: string | null;
  services: {
    id: number;
    name: string;
    price: number;
    duration: number;
  }[];
};

export type IService = {
  id: number;
  name: string;
  category: string | null;
  companyId: number;
  price: number;
  duration: number;
  preparation: number;
  employees: Employee[];
};

export enum ServiceStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type GetAllParams = {
  companyId?: number;
};

export interface GetAllReq extends GetAllParams {}

export type CreateBody = {
  name?: string | null;
  category?: string | null;
  shortDescription?: string | null;
  fullDescription?: string | null;
  price?: number | null;
  duration?: number | null;
  preparation?: number | null;
  status?: ServiceStatus;
  photoUrl?: string | null;
  companyId?: number | null;
  employeeIds?: number[] | null;
};

export type UpdateOneBody = {
  name?: string | null;
  category?: string | null;
  shortDescription?: string | null;
  fullDescription?: string | null;
  price?: number | null;
  duration?: number | null;
  preparation?: number | null;
  status?: ServiceStatus;
  photoUrl?: string | null;
  companyId?: number | null;
  employeeIds?: number[] | null;
};

export type RemoveParams = {
	companyId: number;
	serviceId: string;
};
