import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';
import { Roles } from 'src/auth/types';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);
const settingIcon = (name: string) => (
  <SvgColor src={`/assets/icons/setting/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  setting: settingIcon('ic_setting'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'overview',
        items: [
          {
            title: 'calendar',
            path: paths.dashboard.calendar,
            icon: ICONS.calendar,
            roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER],
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('management'),
        items: [
          {
            title: 'company',
            path: paths.dashboard.company.root,
            icon: ICONS.ecommerce,
            roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER, Roles.USER],
            children: [
              {
                title: 'create',
                path: paths.dashboard.company.new,
                roles: [Roles.ADMIN, Roles.USER],
              },
              {
                title: 'account',
                path: paths.dashboard.company.account,
                roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER],
              },
            ],
          },
          {
            title: 'customer',
            path: paths.dashboard.customer.root,
            icon: ICONS.user,
            roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER, Roles.STAFF],
            children: [
              // { title: 'profile', path: paths.dashboard.customer.root },
              {
                title: 'list',
                path: paths.dashboard.customer.list,
                roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER, Roles.STAFF],
              },
              {
                title: 'create',
                path: paths.dashboard.customer.new,
                roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER, Roles.STAFF],
              },
            ],
          },
          {
            title: 'employee',
            path: paths.dashboard.employee.root,
            icon: ICONS.job,
            roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER],
            children: [
              // { title: 'profile', path: paths.dashboard.customer.root },
              {
                title: 'list',
                path: paths.dashboard.employee.list,
                roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER],
              },
              {
                title: 'create',
                path: paths.dashboard.employee.new,
                roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER],
              },
            ],
          },
          {
            title: 'service',
            path: paths.dashboard.service.root,
            icon: ICONS.product,
            roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER],
            children: [
              // { title: 'profile', path: paths.dashboard.customer.root },
              {
                title: 'list',
                path: paths.dashboard.service.list,
                roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER],
              },
              {
                title: 'create',
                path: paths.dashboard.service.new,
                roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER],
              },
            ],
          },

          // {
          //   title: 'user',
          //   path: paths.dashboard.user.root,
          //   icon: ICONS.user,
          //   children: [
          //     { title: 'profile', path: paths.dashboard.user.root },
          //     { title: 'cards', path: paths.dashboard.user.cards },
          //     { title: 'list', path: paths.dashboard.user.list },
          //     { title: 'create', path: paths.dashboard.user.new },
          //     { title: 'edit', path: paths.dashboard.user.demo.edit },
          //     { title: 'account', path: paths.dashboard.user.account },
          //   ],
          // },
        ],
      },

      // Settings
      // ----------------------------------------------------------------------
      {
        subheader: t('settings'),
        items: [{ title: 'Account', path: paths.dashboard.user.account, icon: ICONS.setting }],
      },
      // PANEL
      // ----------------------------------------------------------------------
      {
        subheader: t('panel'),
        items: [
          {
            title: 'users',
            path: paths.panel.users.list,
            icon: ICONS.user,
            roles: [Roles.ADMIN],
            children: [
              // { title: 'profile', path: paths.dashboard.customer.root },
              {
                title: 'list',
                path: paths.panel.users.list,
                roles: [Roles.ADMIN, Roles.OWNER, Roles.MANAGER, Roles.STAFF],
              },
            ],
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
