import { User as Auth0User } from '@auth0/auth0-spa-js';
import { LogoutOptions, RedirectLoginOptions, PopupLoginOptions } from '@auth0/auth0-react';
import { EmployeeRoles, EmployeeStatus } from 'src/types/employee';
import { CustomerStatus } from 'src/types/customer';
import { Company } from 'src/types/company';

// ----------------------------------------------------------------------

export type ActionMapType<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type Person = {
  email: string;
  name: string;
  phoneCode: string;
  phoneNumber: string;
  address: string;
  birthDate: Date;
  photoUrl: string;
};
export type Employee = {
  person: Person;
  id: number;
  start: Date | null;
  end: Date | null;
  daysOffAnnual: number | null;
  daysOffLeft: number | null;
  daysOffTaken: number | null;
  salaryAnnual: number | null;
  status: EmployeeStatus | null;
  role: EmployeeRoles | null;
  userId: number | null;
  company: Company | null;
  companyId: number | null;
  createdAt: Date;
  updatedAt: Date;
};
export type Customer = {
  person: Person;
  id: number;
  status: CustomerStatus | null;
  userId: number | null;
  company: Company | null;
  companyId: number | null;
  createdAt: Date;
  updatedAt: Date;
};
export enum Roles {
  // Admin level
  ADMIN = 'ADMIN',

  // Company level
  OWNER = 'OWNER',
  MANAGER = 'MANAGER',
  STAFF = 'STAFF',

  // Customer level
  USER = 'USER',
}
export type UserBackend = {
  id: string;
  auth0UserId: string;
  role: Roles;
  person: Person;
  employee: Employee | null;
  createdAt: Date;
  updatedAt: Date;
};

export type AuthUserType = null | Auth0User;

export type AuthStateType = {
  status?: string;
  loading: boolean;
  auth0User?: Auth0User | null;
  user: UserBackend | null;
};

// ----------------------------------------------------------------------

type CanRemove = {
  login?: (email: string, password: string) => Promise<void>;
  register?: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => Promise<void>;
  //
  loginWithGoogle?: () => Promise<void>;
  loginWithGithub?: () => Promise<void>;
  loginWithTwitter?: () => Promise<void>;
  //
  loginWithPopup?: (options?: PopupLoginOptions) => Promise<void>;
  loginWithRedirect?: (options?: RedirectLoginOptions) => Promise<void>;
  //
  confirmRegister?: (email: string, code: string) => Promise<void>;
  forgotPassword?: (email: string) => Promise<void>;
  resendCodeRegister?: (email: string) => Promise<void>;
  newPassword?: (email: string, code: string, password: string) => Promise<void>;
};

export type JWTContextType = CanRemove & {
  user: AuthUserType;
  method: string;
  loading: boolean;
  authenticated: boolean;
  unauthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
  logout: () => Promise<void>;
};

export type FirebaseContextType = CanRemove & {
  user: AuthUserType;
  method: string;
  loading: boolean;
  authenticated: boolean;
  unauthenticated: boolean;
  logout: () => Promise<void>;
  loginWithGoogle: () => Promise<void>;
  loginWithGithub: () => Promise<void>;
  loginWithTwitter: () => Promise<void>;
  forgotPassword?: (email: string) => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, firstName: string, lastName: string) => Promise<void>;
};

export type AmplifyContextType = CanRemove & {
  user: AuthUserType;
  method: string;
  loading: boolean;
  authenticated: boolean;
  unauthenticated: boolean;
  login: (email: string, password: string) => Promise<unknown>;
  register: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => Promise<unknown>;
  logout: () => Promise<unknown>;
  confirmRegister: (email: string, code: string) => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  resendCodeRegister: (email: string) => Promise<void>;
  newPassword: (email: string, code: string, password: string) => Promise<void>;
};

// ----------------------------------------------------------------------

export type Auth0ContextType = CanRemove & {
  auth0User?: Auth0User;
  user: UserBackend;
  method: string;
  loading: boolean;
  authenticated: boolean;
  unauthenticated: boolean;
  loginWithPopup: (options?: PopupLoginOptions) => Promise<void>;
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>;
  logout: (options?: LogoutOptions) => Promise<void>;
};
