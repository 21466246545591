import { _id } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  EVENT: '/event',
  PANEL: '/panel',
};

// ----------------------------------------------------------------------

export const paths = {
  checkBird: 'https://checkbird.app',
  myCheckBird: 'https://my.checkbird.app',
  components: '/components',
  // AUTH
  auth: {
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    customer: {
      root: `${ROOTS.DASHBOARD}/customer`,
      new: `${ROOTS.DASHBOARD}/customer/new`,
      list: `${ROOTS.DASHBOARD}/customer/list`,
      // profile: `${ROOTS.DASHBOARD}/customer/profile`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/customer/${id}/edit`,
    },
    employee: {
      root: `${ROOTS.DASHBOARD}/employee`,
      new: `${ROOTS.DASHBOARD}/employee/new`,
      list: `${ROOTS.DASHBOARD}/employee/list`,
      // profile: `${ROOTS.DASHBOARD}/employee/profile`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/employee/${id}/edit`,
    },
    service: {
      root: `${ROOTS.DASHBOARD}/service`,
      new: `${ROOTS.DASHBOARD}/service/new`,
      list: `${ROOTS.DASHBOARD}/service/list`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/service/${id}/edit`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    company: {
      root: `${ROOTS.DASHBOARD}/company/account`,
      new: `${ROOTS.DASHBOARD}/company/new`,
      account: `${ROOTS.DASHBOARD}/company/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/company/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
  },
  // EVENT
  event: {
    company: {
      new: (id: number) => `${ROOTS.EVENT}/company/${id}/new`,
      cancel: (companyId: number, appointmentPublicId: string) =>
        `${ROOTS.EVENT}/company/${companyId}/cancel/${appointmentPublicId}`,
    },
  },
  // PANEL
  panel: {
    root: ROOTS.PANEL,
    // calendar: `${ROOTS.PANEL}/calendar`,
    users: {
      root: `${ROOTS.PANEL}/users`,
      new: `${ROOTS.PANEL}/users/new`,
      list: `${ROOTS.PANEL}/users/list`,
      // profile: `${ROOTS.PANEL}/customer/profile`,
      edit: (id: string) => `${ROOTS.PANEL}/users/${id}/edit`,
    },
  },
};
