import { atom, useAtom } from 'jotai';
import { UserBackend } from 'src/auth/types';

type ImpersonateUser = {
  name: UserBackend['person']['name'];
  auth0UserId: UserBackend['auth0UserId'];
  companyName: string;
  impersonateByUserId: string;
  impersonateByUserName: string;
};
const impersonateUserAtom = atom<ImpersonateUser>({
  name: '',
  auth0UserId: '',
  companyName: '',
  impersonateByUserId: '',
  impersonateByUserName: '',
});

export const useImpersonateUser = () => {
  const [impersonateUser, setImpersonateUser] = useAtom(impersonateUserAtom);
  return { impersonateUser, setImpersonateUser };
};
